import AWS from 'aws-sdk'

const ApkDownloadButton = ({ styles }) => {
  const download = async () => {
    const s3 = new AWS.S3({
      accessKeyId: process.env.NEXT_PUBLIC_AWS_ACCESS_KEY_ID,
      secretAccessKey: process.env.NEXT_PUBLIC_AWS_SECRET_ACCESS_KEY,
      endpoint: process.env.NEXT_PUBLIC_AWS_ENDPOINT,
    })

    const s3BucketName = process.env.NEXT_PUBLIC_AWS_BUCKET
    const apkFileDirectory = process.env.NEXT_PUBLIC_APK_FILE_DIRECTORY

    const params = {
      Bucket: s3BucketName,
      Prefix: `${apkFileDirectory}/Metaversia`,
    }
    try {
      const data = await s3.listObjectsV2(params).promise()
      if (data.Contents.length > 0) {
        const apkFileName = data.Contents[0].Key.replace(`${apkFileDirectory}/`, '')
        const getObjectParams = {
          Bucket: s3BucketName,
          Key: data.Contents[0].Key,
        }

        const url = await s3.getSignedUrlPromise('getObject', getObjectParams)
        const link = document.createElement('a')
        link.href = url
        link.target = '_blank'
        link.download = apkFileName
        link.click()
      } else {
        console.error('Файлы с префиксом "Metaversia" не найдены.')
      }
    } catch (error) {
      console.error('Ошибка при получении подписанного URL:', error)
    }
  }

  return (
    <div className={styles} onClick={download}>
      Скачать
    </div>
  )
}
export default ApkDownloadButton
