
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __appWithI18n from 'next-translate/appWithI18n'
// @ts-ignore
    
import React, { useEffect, useMemo, useRef, useState } from 'react'
import Slider from 'react-slick'
import clsx from 'clsx'
import Head from 'next/head'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'

import JoinApps from '@/components/JoinApps'
import ApkUploadButton from '@/components/pages_components/s3uploader/ApkUploadButton'
import styles from '@/css/home.module.css'
import public_styles from '@/css/public.module.css'
import { FullLogo } from '@/lib/svg'
import { isDevelopment } from '@/lib/utils'
import { FC } from '@/model/commonModel'

type Slide = {
  img: string
  name: string
  sizes: {
    width: number
    height: number
  }
  description: string
}
type SlideMenuItemType = {
  index: number
  slide: Partial<Pick<Slide, 'sizes'>> & Omit<Slide, 'sizes'>
  sliderRef: React.RefObject<Slider>
  setActive: React.Dispatch<React.SetStateAction<any>>
}

const Home: FC<unknown> = () => {
  const { t } = useTranslation('home')
  const router = useRouter()

  const [activeSlide, setActiveSlide] = useState(0)
  const [activeCombineSlide1, setActiveCombineSlide1] = useState(0)
  const [activeCombineSlide2, setActiveCombineSlide2] = useState(0)
  const [activeCombineSlider, setActiveCombineSlider] = useState('first')
  const [urlParams, setUrlParams] = useState('')

  const slider = useRef<Slider>(null)
  // const partnersSlider = useRef<Slider>(null);
  const reviewsSlider = useRef<Slider>(null)
  const combineSlider1 = useRef<Slider>(null)
  const combineSlider2 = useRef<Slider>(null)
  const combineSlider = useRef<Slider>(null)

  /* Slider initial settings */
  const initialSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    arrows: false,
    slidesToScroll: 1,
    slidesToShow: 1,
    autoplay: true,
    autoplaySpeed: 6000,
  }

  const initialSlider1Settings = {
    ...initialSettings,
    ...{
      afterChange: (index: number) => {
        setActiveSlide(index)
      },
    },
  }

  const initialCombineSliderSettings = {
    ...initialSettings,
    ...{
      autoplay: false,
      infinite: false,
      swipe: false,
      touchMove: false,
    },
  }

  const initialCombineSlider1Settings = {
    ...initialSettings,
    ...{
      autoplaySpeed: 7000,
      afterChange: (index: number) => {
        setActiveCombineSlide1(index)
      },
    },
  }

  const initialCombineSlider2Settings = {
    ...initialSettings,
    ...{
      autoplaySpeed: 7000,
      afterChange: (index: number) => {
        setActiveCombineSlide2(index)
      },
    },
  }

  const sliderElements1: Slide[] = [
    {
      img: '/img/home/slider/slide1.png',
      name: t('slider1.menu.item1.name'),
      sizes: {
        width: 325,
        height: 660,
      },
      description: t('slider1.menu.item1.description'),
    },
    {
      img: '/img/home/slider/slide3.png',
      name: t('slider1.menu.item3.name'),
      sizes: {
        width: 600,
        height: 600,
      },
      description: t('slider1.menu.item3.description'),
    },
    {
      img: '/img/home/slider/slide5.png',
      name: t('slider1.menu.item5.name'),
      sizes: {
        width: 600,
        height: 600,
      },
      description: t('slider1.menu.item5.description'),
    },
  ]

  const combinedSliderElements1: Omit<Slide, 'sizes'>[] = useMemo(
    () => [
      {
        img: '/img/home/combine_slider1/slide1.png',
        name: t('combined_slider.slider1.menu.item1.name'),
        description: t('combined_slider.slider1.menu.item1.description'),
      },
      {
        img: '/img/home/combine_slider1/slide2.png',
        name: t('combined_slider.slider1.menu.item2.name'),
        description: t('combined_slider.slider1.menu.item2.description'),
      },
      {
        img: '/img/home/combine_slider1/slide3.png',
        name: t('combined_slider.slider1.menu.item3.name'),
        description: t('combined_slider.slider1.menu.item3.description'),
      },
    ],
    [t],
  )

  const combinedSliderElements2: Omit<Slide, 'sizes'>[] = [
    {
      img: '/img/home/combine_slider2/slide1.png',
      name: t('combined_slider.slider2.menu.item1.name'),
      description: t('combined_slider.slider2.menu.item1.description'),
    },
    {
      img: '/img/home/combine_slider2/slide2.png',
      name: t('combined_slider.slider2.menu.item2.name'),
      description: t('combined_slider.slider2.menu.item2.description'),
    },
    {
      img: '/img/home/combine_slider2/slide3.png',
      name: t('combined_slider.slider2.menu.item3.name'),
      description: t('combined_slider.slider2.menu.item3.description'),
    },
  ]

  // const partnersSliderElements: Omit<Slide, 'description' | 'sizes'>[] = [
  //     {
  //         img: t('partners.list.item1.image'),
  //         name: t('partners.list.item1.title'),
  //     },
  //     {
  //         img: t('partners.list.item2.image'),
  //         name: t('partners.list.item2.title'),
  //     },
  //     {
  //         img: t('partners.list.item3.image'),
  //         name: t('partners.list.item3.title'),
  //     },
  //     {
  //         img: t('partners.list.item4.image'),
  //         name: t('partners.list.item14.title'),
  //     },
  //     {
  //         img: t('partners.list.item1.image'),
  //         name: t('partners.list.item5.title'),
  //     },
  // ];

  const reviewsElements: Omit<Slide, 'sizes'>[] = [1, 2, 3].map((index) => ({
    img: t(`reviews.item${index}.image`),
    name: t(`reviews.item${index}.name`),
    description: t(`reviews.item${index}.content`),
  }))

  const SliderMenuItem = ({ index, slide, sliderRef, setActive }: SlideMenuItemType) => {
    const onSliderMenuClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault()
      setActive(index)
      sliderRef.current && sliderRef.current.slickGoTo(index)
    }

    const onSliderNext = () => {
      sliderRef.current && sliderRef.current.slickNext()
    }

    const onSliderPrev = () => {
      sliderRef.current && sliderRef.current.slickPrev()
    }
    return (
      <div className={clsx('d-flex align-items-center', styles.slider__arrow_block)}>
        <svg
          onClick={onSliderPrev}
          className={clsx(styles.slider__arrow, styles.slider__left_arrow)}
          viewBox='0 0 11 16'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path d='M9.86667 6.4C10.9333 7.2 10.9333 8.8 9.86667 9.6L3.2 14.6C1.88153 15.5889 -6.84e-07 14.6481 -6.11959e-07 13L-1.74846e-07 3C-1.02805e-07 1.35191 1.88153 0.411145 3.2 1.4L9.86667 6.4Z' />
        </svg>
        <a onClick={onSliderMenuClick} href='#' title={slide.name}>
          {slide.name}
        </a>
        <svg
          onClick={onSliderNext}
          className={clsx(styles.slider__arrow, styles.slider__right_arrow)}
          viewBox='0 0 11 16'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path d='M9.86667 6.4C10.9333 7.2 10.9333 8.8 9.86667 9.6L3.2 14.6C1.88153 15.5889 -6.84e-07 14.6481 -6.11959e-07 13L-1.74846e-07 3C-1.02805e-07 1.35191 1.88153 0.411145 3.2 1.4L9.86667 6.4Z' />
        </svg>
      </div>
    )
  }

  // eslint-disable-next-line react/display-name
  const SliderControls = useMemo(
    () =>
      ({ sliderRef }: { sliderRef: React.RefObject<Slider> }) => {
        const onSliderNext = () => {
          sliderRef.current && sliderRef.current.slickNext()
        }

        const onSliderPrev = () => {
          sliderRef.current && sliderRef.current.slickPrev()
        }
        return (
          <>
            <svg
              onClick={onSliderPrev}
              className={clsx(styles.slider__arrow, styles.slider__left_arrow)}
              viewBox='0 0 11 16'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path d='M9.86667 6.4C10.9333 7.2 10.9333 8.8 9.86667 9.6L3.2 14.6C1.88153 15.5889 -6.84e-07 14.6481 -6.11959e-07 13L-1.74846e-07 3C-1.02805e-07 1.35191 1.88153 0.411145 3.2 1.4L9.86667 6.4Z' />
            </svg>
            <svg
              onClick={onSliderNext}
              className={clsx(styles.slider__arrow, styles.slider__right_arrow)}
              viewBox='0 0 11 16'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path d='M9.86667 6.4C10.9333 7.2 10.9333 8.8 9.86667 9.6L3.2 14.6C1.88153 15.5889 -6.84e-07 14.6481 -6.11959e-07 13L-1.74846e-07 3C-1.02805e-07 1.35191 1.88153 0.411145 3.2 1.4L9.86667 6.4Z' />
            </svg>
          </>
        )
      },
    [],
  )

  const handleExploreClick = () => {
    const exploreBlockDOM = document.getElementById('explore-block')
    exploreBlockDOM && exploreBlockDOM.scrollIntoView({ behavior: 'smooth' })
  }

  const CombineSliderControls = () => {
    const Control = ({ sliderIndex, index }: { sliderIndex: string; index: number }) => {
      const handleActiveCombineSlider = () => {
        setActiveCombineSlider(sliderIndex)
        combineSlider.current && combineSlider.current.slickGoTo(index)
      }

      return (
        <a
          title={t(`combined_slider.controls.${sliderIndex}`)}
          onClick={handleActiveCombineSlider}
          className={activeCombineSlider === sliderIndex ? styles.selected : undefined}
        >
          {t(`combined_slider.controls.${sliderIndex}`)}
        </a>
      )
    }

    return (
      <div className={styles.combined_sliders_controls}>
        <Control sliderIndex={'first'} index={0} />
        {/* <Control sliderIndex={'second'} index={1} /> */}
      </div>
    )
  }

  useEffect(() => {
    let params: Record<string, string> = {}
    let searchParams = ''
    const referer = document.referrer ? new URL(document.referrer).hostname : ''

    if (Object.keys(router.query).length) {
      params = Object.keys(router.query).reduce((accum, curVal) => {
        if (curVal.includes('utm_') && router.query[curVal]) {
          accum[curVal] = router.query[curVal] as string
        }
        return accum
      }, params)

      if (!params['utm_source'] && referer) {
        params['utm_source'] = referer
      }
      if (!params['utm_campaign']) {
        params['utm_campaign'] = `home${referer ? `[${referer}]` : ``}`
      }

      if (Object.keys(params).length) {
        searchParams = new URLSearchParams(params).toString()
        setUrlParams((prev) => (prev.length ? `${prev}&` : '') + searchParams)
      }
    } else {
      setUrlParams(`utm_campaign=home${referer ? `[${referer}]&utm_source=${referer}` : ``}`)
    }
  }, [router.query])

  return (
    <>
      <Head>
        <title>{t('title')}</title>
        <meta name='description' content={t('description')} />

        <meta property='og:title' content={t('title')} />
        <meta property='og:description' content={t('description')} />
        {/* <meta property='og:url' content={`https://${isDevelopment ? 'stage.' : ''}connect.club`} /> */}
      </Head>
      {/* <div className={clsx(styles.promo, 'relative')}>*/}
      {/*    <div className={styles.promo__title}>{t('connectcon.title')}</div>*/}
      {/*    <Link href='/l/connectcon-nft'>*/}
      {/*        <a title={t('connectcon.button')} className={styles.promo__button}>*/}
      {/*            {t('connectcon.button')}*/}
      {/*        </a>*/}
      {/*    </Link>*/}
      {/*    <PromoBg />*/}
      {/*</div> */}

      {/* <div className={clsx(styles.promo, styles.header, 'relative')}>
        <div className={styles.promo__title} dangerouslySetInnerHTML={{ __html: t('header.title') }} />
        <Link href={'https://www.rustore.ru'}>
          <a title={t('header.button')} className={styles.promo__button}>
            {t('header.button')}
          </a>
        </Link>
      </div> */}

      <div className={clsx(styles.promo, styles.header, 'relative')}>
        <div className={styles.promo__title} dangerouslySetInnerHTML={{ __html: t('header.title') }} />
        <div>
          <ApkUploadButton styles={styles.promo__button} />
        </div>
      </div>

      <div className={clsx(styles.home_page)}>
        <a
          href='https://t.me/metaversia_ru_help_bot'
          className={styles.discord_button}
          title={'Присоедяниться к нашему Telegram каналу'}
        >
          <Image src='/img/svg/telegram.svg' width={96} height={96} alt={'Присоедяниться к нашему Telegram каналу'} />
        </a>

        <section className={clsx(public_styles.block, styles.first_block, 'smooth-scroll-section')}>
          <div className={clsx(styles.cols, 'container relative')}>
            <div className={clsx('d-flex align-items-center gutter-1', styles.logo)}>
              <Link href='/'>
                <a title='Метаверсия' style={{ width: '200px' }}>
                  <FullLogo id='header' />
                </a>
              </Link>
            </div>
            <div className={styles.cols__col_left}>
              <h1 className={clsx(public_styles.h1, styles.cols__title)}>{t('h1')}</h1>
              <p>{t('main_description')}</p>
              <a title={t('explore_button')} className={styles.button} onClick={handleExploreClick}>
                {t('explore_button')}
              </a>
            </div>
            <div className={styles.cols__col_right}>
              <div className={styles.cols__video}>
                <video width='100%' loop autoPlay playsInline muted>
                  <source src='/img/home/video.mp4' type='video/mp4' />
                </video>
                <Image
                  src='/img/home/phone1.png'
                  width={480}
                  height={975}
                  quality={100}
                  priority={true}
                  alt={t('h1')}
                />
              </div>
            </div>
          </div>
        </section>

        {/*<section className={clsx(public_styles.block_small)}>*/}
        {/*    <div className={'container'}>*/}
        {/*        <div className={'h2 align-center'}>{t('partners.heading')}</div>*/}
        {/*        <div className={styles.partners_slider}>*/}
        {/*            <Slider {...initialPartnersSliderSettings} ref={partnersSlider}>*/}
        {/*                {partnersSliderElements.map((slide, index) => (*/}
        {/*                    <div key={index} className={clsx(styles.partners_image)}>*/}
        {/*                        <Image src={slide.img}*/}
        {/*                               layout={'fill'}*/}
        {/*                               quality={100}*/}
        {/*                               alt={slide.name}*/}
        {/*                               objectFit={'cover'}*/}
        {/*                        />*/}
        {/*                    </div>*/}
        {/*                ))}*/}
        {/*            </Slider>*/}
        {/*            <SliderControls sliderRef={partnersSlider} />*/}
        {/*        </div>*/}
        {/*    </div>*/}
        {/*</section>*/}

        <section className={clsx(public_styles.block_small)}>
          <div className={'container'}>
            <div className={styles.reviews_slider}>
              <Slider {...initialSettings} autoplaySpeed={6500} ref={reviewsSlider}>
                {reviewsElements.map((slide, index) => (
                  <div key={index} className={styles.reviews_slider_item}>
                    <div className={styles.reviews_slider_image}>
                      <Image src={slide.img} width={144} height={144} objectFit={'cover'} alt={slide.name} />
                    </div>
                    <div>
                      <div className={styles.reviews_slider_content}>{slide.description}</div>
                      <div className={styles.reviews_slider_name}>{slide.name}</div>
                    </div>
                  </div>
                ))}
              </Slider>
              <SliderControls sliderRef={reviewsSlider} />
            </div>
          </div>
        </section>

        <section id={'explore-block'} className={clsx(public_styles.block, 'smooth-scroll-section')}>
          <div className={clsx(styles.cols, styles.slider__block, 'container relative')}>
            <div className={clsx(styles.cols__col_left, styles.with_circle)}>
              <Slider {...initialSlider1Settings} ref={slider}>
                {sliderElements1.map((slide, index) => (
                  <div key={index} className={clsx(styles.slider_item, styles.with_circle)}>
                    <Image
                      src={slide.img}
                      width={slide.sizes.width}
                      height={slide.sizes.height}
                      quality={100}
                      alt={slide.name}
                      priority={true}
                    />
                  </div>
                ))}
              </Slider>
            </div>
            <div className={styles.cols__col_right}>
              <div className={clsx(public_styles.h1, styles.cols__title)}>{t('slider1.title')}</div>
              <ul className={styles.slider__menu}>
                {sliderElements1.map((slide, index) => (
                  <li className={activeSlide === index ? styles.selected : undefined} key={index}>
                    <SliderMenuItem index={index} slide={slide} sliderRef={slider} setActive={setActiveSlide} />
                    <p>{slide.description}</p>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </section>

        <section className={clsx(public_styles.block_small, 'smooth-scroll-section')}>
          <div className={styles.combined_sliders}>
            <CombineSliderControls />

            <div>
              <Slider {...initialCombineSliderSettings} ref={combineSlider}>
                <div
                  className={clsx(
                    styles.combined_slider_first,
                    activeCombineSlider !== 'first' ? styles.hide_combine_slider : undefined,
                  )}
                >
                  <div className={clsx(styles.cols, styles.cols_revert, styles.slider__block, 'container relative')}>
                    <div className={styles.cols__col_left}>
                      <ul className={styles.slider__menu}>
                        {combinedSliderElements1.map((slide, index) => (
                          <li className={activeCombineSlide1 === index ? styles.selected : undefined} key={index}>
                            <SliderMenuItem
                              index={index}
                              slide={slide}
                              sliderRef={combineSlider1}
                              setActive={setActiveCombineSlide1}
                            />
                            <p>{slide.description}</p>
                          </li>
                        ))}
                      </ul>
                      <div className={styles.combined_slider_button_block}>
                        {/* <Link href={`/club/united-metaverse-by-connect-club?${urlParams || 'utm_campaign=home'}`}>
                          <a title={t('combined_slider.slider1.button')} className={styles.button}>
                            {t('combined_slider.slider1.button')}
                          </a>
                        </Link> */}

                        <div>
                          <ApkUploadButton styles={styles.button} />
                        </div>

                        <div
                          className={styles.combined_slider_extra}
                          dangerouslySetInnerHTML={{
                            __html: t('combined_slider.slider1.extra'),
                          }}
                        />
                      </div>
                    </div>
                    <div className={clsx(styles.cols__col_right, styles.with_circle, styles.mobile_no_circle)}>
                      <Slider {...initialCombineSlider1Settings} ref={combineSlider1}>
                        {combinedSliderElements1.map((slide, index) => (
                          <div
                            key={index}
                            className={clsx(styles.slider_item, styles.with_circle, styles.mobile_no_circle)}
                          >
                            <Image
                              src={slide.img}
                              width={325}
                              height={660}
                              quality={100}
                              alt={slide.name}
                              priority={true}
                            />
                          </div>
                        ))}
                      </Slider>
                    </div>
                  </div>
                </div>

                {/* <div
                  className={clsx(
                    styles.combined_slider_second,
                    activeCombineSlider !== 'second' ? styles.hide_combine_slider : undefined,
                  )}
                >
                  <div className={clsx(styles.cols, styles.slider__block, 'container relative')}>
                    <div className={clsx(styles.cols__col_left, styles.with_circle, styles.mobile_no_circle)}>
                      <Slider {...initialCombineSlider2Settings} ref={combineSlider2}>
                        {combinedSliderElements2.map((slide, index) => (
                          <div
                            key={index}
                            className={clsx(styles.slider_item, styles.with_circle, styles.mobile_no_circle)}
                          >
                            <Image
                              src={slide.img}
                              width={325}
                              height={660}
                              quality={100}
                              alt={slide.name}
                              priority={true}
                            />
                          </div>
                        ))}
                      </Slider>
                    </div>
                    <div className={styles.cols__col_right}>
                      <ul className={styles.slider__menu}>
                        {combinedSliderElements2.map((slide, index) => (
                          <li className={activeCombineSlide2 === index ? styles.selected : undefined} key={index}>
                            <SliderMenuItem
                              index={index}
                              slide={slide}
                              sliderRef={combineSlider2}
                              setActive={setActiveCombineSlide2}
                            />
                            <p>{slide.description}</p>
                          </li>
                        ))}
                      </ul>
                      <div className={styles.combined_slider_button_block}> */}

                {/* <Link href={`/club/united-metaverse-by-connect-club?${urlParams || 'utm_campaign=home'}`}>
                          <a title={t('combined_slider.slider2.button')} className={styles.button}>
                            {t('combined_slider.slider2.button')}
                          </a>
                        </Link> */}

                {/* <div className={styles.button}>
                            <ApkUploadButton />
                        </div>
                        
                        <div
                          className={styles.combined_slider_extra}
                          dangerouslySetInnerHTML={{
                            __html: t('combined_slider.slider2.extra'),
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div> */}
              </Slider>
            </div>
          </div>
        </section>

        {/*<section className={clsx(public_styles.block_small)}>*/}
        {/*    <div className={'container'}>*/}
        {/*        <div className={clsx(styles.h2, 'align-center')}>{t('featured')}</div>*/}
        {/*        <div className={'d-flex justify-content-between'}>*/}
        {/*            {featuredElements.map((img, index) => (*/}
        {/*                <div key={index}>*/}
        {/*                    <Image src={img}*/}
        {/*                           width={198}*/}
        {/*                           height={60}*/}
        {/*                           quality={100}*/}
        {/*                           alt={'Featured in'}*/}
        {/*                           objectFit={'cover'}*/}
        {/*                    />*/}
        {/*                </div>*/}
        {/*            ))}*/}
        {/*        </div>*/}
        {/*    </div>*/}
        {/*</section>*/}

        {/*<section className={clsx(public_styles.block, 'smooth-scroll-section')}>*/}
        {/*    <div className={clsx(styles.cols, styles.cols_revert, 'container')}>*/}
        {/*        <div className={clsx(styles.cols__col_left)}>*/}
        {/*            <div className={clsx(public_styles.h1, styles.cols__title)}>*/}
        {/*                {t('section3.title')}*/}
        {/*            </div>*/}
        {/*            <p>{t('section3.description')}</p>*/}
        {/*        </div>*/}
        {/*        <div className={clsx(styles.cols__col_right, styles.with_circle)}>*/}
        {/*            <Image src='/img/home/phone2.png'*/}
        {/*                   width={480}*/}
        {/*                   height={970}*/}
        {/*                   quality={100}*/}
        {/*                   alt={t('section3.title')}*/}
        {/*            />*/}
        {/*        </div>*/}
        {/*    </div>*/}
        {/*</section>*/}

        {/*<section className={clsx(public_styles.block, 'smooth-scroll-section')}>*/}
        {/*    <div className={clsx(styles.cols, styles.slider__block, 'container relative')}>*/}
        {/*        <div className={clsx(styles.cols__col_left, styles.with_circle, styles.mobile_white_circle)}>*/}
        {/*            <Slider {...initialSlider2Settings} ref={slider2}>*/}
        {/*                {sliderElements2.map((slide, index) => (*/}
        {/*                    <div key={index} className={clsx(styles.slider_item, styles.with_circle)}>*/}
        {/*                        <Image src={slide.img}*/}
        {/*                               width={slide.sizes.width}*/}
        {/*                               height={slide.sizes.height}*/}
        {/*                               quality={100}*/}
        {/*                               alt={slide.name}*/}
        {/*                               priority={true}*/}
        {/*                        />*/}
        {/*                    </div>*/}
        {/*                ))}*/}
        {/*            </Slider>*/}
        {/*        </div>*/}
        {/*        <div className={styles.cols__col_right}>*/}
        {/*            <div className={clsx(public_styles.h1, styles.cols__title)}>*/}
        {/*                {t('slider2.title')}*/}
        {/*            </div>*/}
        {/*            <ul className={styles.slider__menu}>*/}
        {/*                {sliderElements2.map((slide, index) => (*/}
        {/*                    <li className={activeSlide2 === index ? styles.selected : undefined} key={index}>*/}
        {/*                        <SliderMenuItem index={index} slide={slide} sliderRef={slider2} />*/}
        {/*                        <p>{slide.description}</p>*/}
        {/*                    </li>*/}
        {/*                ))}*/}
        {/*            </ul>*/}
        {/*        </div>*/}
        {/*    </div>*/}
        {/*</section>*/}

        {/* <section className={clsx(public_styles.block, 'smooth-scroll-section')}>
          <div className={clsx('container relative')}>
            <div className={clsx(public_styles.h1, styles.cols__title)}>{t('section5.title')}</div>
            <p className={'mb-sm'}>
              Наша цель{` `}
              <Link href={'/dao'}>
                <a title={'launch DAO'}>Запустить DAO</a>
              </Link>
              {` `}
              для улучшения пользовательского опыта, создания новых возможностей, обменной стоимости, поддержки идей и
              новых проектов, позволяют создателям монетизировать свою деятельность и создавать ощутимую ценность для
              будущего Метавселенной.
            </p>
            <p>{t('section5.description')}</p>
          </div>
        </section> */}

        <section className={clsx(public_styles.block, styles.last_block, 'smooth-scroll-section')}>
          <div className={clsx('container relative')}>
            <div className={clsx(public_styles.h1, styles.cols__title)}>{t('section4.title')}</div>
            <p>{t('section4.description')}</p>
            <JoinApps className={styles.apps} />
          </div>
        </section>

        <style global jsx>
          {`
            .slick-list,
            .slick-slider,
            .slick-track {
              position: relative;
              display: block;
            }
            .slick-loading .slick-slide,
            .slick-loading .slick-track {
              visibility: hidden;
            }
            .slick-slider {
              box-sizing: border-box;
              -webkit-user-select: none;
              -moz-user-select: none;
              -ms-user-select: none;
              user-select: none;
              -webkit-touch-callout: none;
              -khtml-user-select: none;
              -ms-touch-action: pan-y;
              touch-action: pan-y;
              -webkit-tap-highlight-color: transparent;
            }
            .slick-list {
              overflow: hidden;
              margin: 0;
              padding: 0;
            }
            .slick-list:focus {
              outline: 0;
            }
            .slick-list.dragging {
              cursor: pointer;
              cursor: hand;
            }
            .slick-slider .slick-list,
            .slick-slider .slick-track {
              -webkit-transform: translate3d(0, 0, 0);
              -moz-transform: translate3d(0, 0, 0);
              -ms-transform: translate3d(0, 0, 0);
              -o-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0);
            }
            .slick-track {
              top: 0;
              left: 0;
            }
            .slick-track:after,
            .slick-track:before {
              display: table;
              content: '';
            }
            .slick-track:after {
              clear: both;
            }
            .slick-slide {
              display: none;
              float: left;
              height: 100%;
              min-height: 1px;
            }
            .slick-slide img {
              display: block;
            }
            .slick-slide.slick-loading img {
              display: none;
            }
            .slick-slide.dragging img {
              pointer-events: none;
            }
            .slick-initialized .slick-slide {
              display: block;
            }
            .slick-vertical .slick-slide {
              display: block;
              height: auto;
              border: 1px solid transparent;
            }
          `}
        </style>
      </div>
    </>
  )
}

const __Page_Next_Translate__ = Home


// @ts-ignore
    export default __appWithI18n(__Page_Next_Translate__, {
// @ts-ignore
      ...__i18nConfig,
// @ts-ignore
      isLoader: true,
// @ts-ignore
      skipInitialProps: false,
// @ts-ignore
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
    });
// @ts-ignore
  